body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background-color: #817f88;
}

html,
body {

}

.build-info-highlight {
    font-family: Roboto, serif;
    color: white;
    size: 14pt;
    text-transform: uppercase;
}

.build-info-normal {
    font-family: Roboto, serif;
    color: white;
    size: 10pt;
}

.build-info-offline {
    font-family: Roboto, serif;
    color: #db8388;
    size: 10pt;
}

.fullWidth {
    width: 100%
}

.fittingContainer {
    display: inline-block;
}

.vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

.wordCloudContainer {
    border-radius: 25px;
    width: 400px;
}

.margin-2px {
    margin: 2px;
}

.margin-5px {
    margin: 5px;
}

.mui-margin {
    margin: 8px
}

.padding-8px {
    padding: 8px;
}

.padding-left-16px {
    padding-left: 16px;
}

.padding-left-32px {
    padding-left: 32px;
}

.pwr-selected-list-item {
    background-color: rgba(0, 0, 0, 0.3) !important;
}

.aspect-1-1 {
    padding-top: 100%;
    width: 100%;
}

.vertical-align {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.indentedSkillCategory {
    margin-left: 20px;
}

.cursor-pointer {
    cursor: pointer;
}

.Aligner {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Pwr-Content-Container {
    flex-wrap: wrap;
    display: flex;
    align-items: start;
    justify-content: start;
}

.Aligner-item--top {
    align-self: flex-start;
}

.Aligner-item--bottom {
    align-self: flex-end;
}

.error-text {
    color: red;
}

.warning-note {
    color: orange;
    font-size: 13px;
}

.bold-mui {
    color: rgba(0, 0, 0, 0.87);
    font-weight: bold;
}

.highlighted-category {
    color: #ffc01b;
    margin-top: 8px;
    margin-bottom: 8px
}

.blacklisted-icon {
    color: crimson !important;
}

.material-icons-custom {
    font-family: 'Material Icons', serif;
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

#skill-tree-info-panel {
    position: fixed;
    top: 80px;
    width: 404px;
}

#admin-info-panel {
    position: fixed;
    width: 444px;
}

.sticky-top {
    position: sticky;
    top: 0;
}

.float-right {
    float: right
}

.float-left {
    float: left
}

.logo-small {
    height: 48px
}

.logo-medium {
    height: 92px
}

.power-name-logo {
    color: rgb(70, 230, 230)
}

.hbt-dark-blue-text {
    color: rgb(25, 30, 85)
}

.disabled-consultant-img {
    opacity: 0.4;
    filter: alpha(opacity=40);
}

.dashboard-element {
    padding: 8px;
    margin-top: 8px;
}

.icon-ok-button {
    color: green;
    size: 80px;
}

.icon-size-70 {
    size: 70px;
}

.icon-size-20 {
    width: 20px;
    height: 20px;
}

.icon-size-80 {
    width: 80px;
    height: 80px;
}

.refresh-indicator {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.admin-app-bar-spacer {
    margin-top: 70px;
}

.tree-leaf {
    margin-left: 32px;
}

.pwr-btn-error {
    background-color: #ff8e01;
    color: white;
}

.pwr-flat-warn-button {
    color: #ff8e01 !important;
}

.report-text-field {
    margin: 4px;
    padding: 2px;
}

.Report-Uploader {
    padding: 30px;
    display: inline-block;
    width: 100%;
}

.pwr-profile-entry-container {
    padding-left: 8px;
    padding-bottom: 8px;
}

.pwr-profile-entry-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.pwr-spacer-small {
    margin-top: 4px;
    margin-bottom: 4px;
}

.pwr-margin-bottom {
    margin-bottom: 8px !important;
}

.pwr-margin-top {
    margin-top: 8px !important;
}

.hbt-schild-background {
    position: fixed;
    z-index: -1;
    background-image: url("./img/HBT_Schild_01.jpeg");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    height: 100vh;
    width: 100vw;
}

.select-in-toolbar .MuiSelect-select {
    color: white;
}

.select-in-toolbar .MuiSelect-icon {
    color: white;
}

/*[class*="MuiInputAdornment-root"] {*/
/*height: auto !important;*/
/*}*/
